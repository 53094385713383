import React, { useMemo } from 'react';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import BigNumber from 'bignumber.js';
import { makeStyles } from '@material-ui/core/styles';

import { formatApy, formatTvl, calcDaily } from 'features/helpers/format';
import { byDecimals } from 'features/helpers/bignumber';
import styles from './styles';
import PoolPaused from './PoolPaused/PoolPaused';
import PoolTitle from './PoolTitle/PoolTitle';
import LabeledStat from './LabeledStat/LabeledStat';

const useStyles = makeStyles(styles);

const PoolSummary = ({
  pool,
  launchpool,
  toggleCard,
  balanceSingle,
  sharesBalance,
  apy,
  fetchBalancesDone,
  fetchApysDone,
  fetchVaultsDataDone,
  index,
  openCard,
  page
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const vaultStateTitle = useMemo(() => {
    let state =
      pool.status === 'eol'
        ? t('Vault-DepositsRetiredTitle')
        : pool.paused
          ? t('Vault-DepositsPausedTitle')
          : null;

    if (launchpool) {
      state = t('Stake-BoostedBy', { name: launchpool.name });
    }

    if (pool.experimental) {
      state = t('Vault-Experimental');
    }

    return state === null ? (
      ''
    ) : (
      <PoolPaused
        message={t(state)}
        isBoosted={!!launchpool}
        isExperimental={!!pool.experimental}
      />
    );
  }, [pool, launchpool]);

  const balanceUsd =
    balanceSingle > 0 && fetchVaultsDataDone ? formatTvl(balanceSingle, pool.oraclePrice) : '';
  const deposited = byDecimals(
    sharesBalance.multipliedBy(new BigNumber(pool.pricePerFullShare)),
    pool.tokenDecimals
  );
  const depositedUsd =
    deposited > 0 && fetchVaultsDataDone ? formatTvl(deposited, pool.oraclePrice) : '';
  const mobilePadding = balanceSingle > 0 || deposited > 0 ? '24px' : '10px';

  console.log("launcc",pool)
  return (

    <div style={{ padding: "20px", width: "100%", marginTop:`${page=="detail"?"-30px":""}`,zIndex:"-1" }}>
     {page !== "detail" && <PoolTitle
        name={pool.name}
        logo={pool.logo}
        poolId={pool.id}
        description={t('Vault-Description', { vault: pool.tokenDescription })}
        launchpool={launchpool}
        addLiquidityUrl={pool.addLiquidityUrl}
        removeLiquidityUrl={pool.removeLiquidityUrl}
        buyTokenUrl={pool.buyTokenUrl}
      />}

      <Grid style={{backgroundColor:"#191d59",zIndex:"-1"}}>
        <Grid
          item
          xs={12}
          style={{ border: "1px solid white", marginTop: "30px", padding: "10px", borderRadius: "10px" }}
        >
          <Grid
            item
            xs={12}
            style={{ display: 'flex', paddingTop: mobilePadding }}
            className={classes.mobilePadding}
          >
            <LabeledStat
              value={formatDecimals(balanceSingle)}
              subvalue={balanceUsd}
              label={t('Vault-Balance')}
              isLoading={!fetchBalancesDone}
              xs={6}
            />
            <LabeledStat
              label={"Price"}
              isLoading={!fetchApysDone}
              value={pool.oraclePrice ? `$${pool.oraclePrice.toFixed(2)}` : ''}
              boosted={launchpool ? formatApy(launchpool.apy + apy) : ''}

              xs={4}
              align="start"
            />

<LabeledStat
              value={formatApy(apy)}
              label={t('Vault-APY')}
              isLoading={!fetchApysDone}
              boosted={launchpool ? formatApy(launchpool.apy + apy) : ''}
              xs={4}
              align="start"
            />

          </Grid>
          <Grid item xs={12} style={{ display: 'flex', paddingTop: '20px' }}>
            <LabeledStat
              value={formatDecimals(deposited)}
              subvalue={depositedUsd}
              label={t('Vault-Deposited')}
              isLoading={!fetchBalancesDone}
              xs={6}
              align="start"
            />

            <LabeledStat
              value={calcDaily(apy)}
              label={t('Vault-APYDaily')}
              isLoading={!fetchApysDone}
              boosted={launchpool ? calcDaily(launchpool.apy + apy) : ''}
              xs={4}
            />

            <LabeledStat
              value={formatTvl(pool.tvl, pool.oraclePrice)}
              label={t('Vault-TVL')}
              isLoading={!fetchVaultsDataDone}
              xs={4} />

          </Grid>
        </Grid>
       {page !=="detail" && <Grid xs={12} style={{ marginTop: "30px" }}>
          <Grid xs={12} style={{ display: "inline", textAlign: "center" }}>
            <button style={{ padding: "10px", fontSize: "16px", borderRadius: "19px", width: "100%", color: "White", fontWeight: "bold", background: "#b257c7" }} onClick={()=>{openCard()}}>
              Stake 
            </button>
          </Grid>
          {/* <Grid xs={3} style={{ display: "inline", textAlign: "center", float:"right" }}>
            <button onClick={() => { console.log("hiiii"); openCard() }} style={{ padding: "10px", fontSize: "16px", borderRadius: "19px", width: "50px", color: "White", fontWeight: "bold", background: "#b257c7", marginLeft: "24px" }}>
              <i class='fas fa-angle-down'></i>
            </button>
          </Grid> */}
        </Grid>}

      </Grid>



    </div >
    // <AccordionSummary
    //   className={
    //     pool.status === 'eol'
    //       ? classes.detailsRetired
    //       : pool.depositsPaused
    //         ? classes.detailsPaused
    //         : classes.details
    //   }
    //   style={{ justifyContent: 'space-between', borderRadius: "20px", paddingRight: "20px", textAlign: "left" }}
    //   // onClick={toggleCard}
    // >
    //   <Grid
    //     container
    //     alignItems="left"
    //     style={{ paddingTop: '20px', paddingBottom: '20px' }}
    //   >
    //     {vaultStateTitle}
    //     <PoolTitle
    //       name={pool.name}
    //       logo={pool.logo}
    //       poolId={pool.id}
    //       description={t('Vault-Description', { vault: pool.tokenDescription })}
    //       launchpool={launchpool}
    //       addLiquidityUrl={pool.addLiquidityUrl}
    //       removeLiquidityUrl={pool.removeLiquidityUrl}
    //       buyTokenUrl={pool.buyTokenUrl}
    //     />
    // <Grid item md={7} xs={7}>
    //   <Grid item container justify="space-between">
    //     <Hidden smDown>
    //       <LabeledStat
    //         value={formatDecimals(balanceSingle)}
    //         subvalue={balanceUsd}
    //         label={t('Vault-Balance')}
    //         isLoading={!fetchBalancesDone}
    //         xs={5}
    //         md={3}
    //       />
    //       <LabeledStat
    //         value={formatDecimals(deposited)}
    //         subvalue={depositedUsd}
    //         label={t('Vault-Deposited')}
    //         isLoading={!fetchBalancesDone}
    //         xs={5}
    //         md={3}
    //         align="start"
    //       />
    //       <LabeledStat
    //         value={formatApy(apy)}
    //         label={t('Vault-APY')}
    //         boosted={launchpool ? formatApy(launchpool.apy + apy) : ''}
    //         isLoading={!fetchApysDone}
    //         xs={5}
    //         md={2}
    //         align="start"
    //       />
    //       <LabeledStat
    //         value={calcDaily(apy)}
    //         label={t('Vault-APYDaily')}
    //         boosted={launchpool ? calcDaily(launchpool.apy + apy) : ''}
    //         isLoading={!fetchApysDone}
    //         xs={5}
    //         md={2}
    //       />
    //       <LabeledStat
    //         value={formatTvl(pool.tvl, pool.oraclePrice)}
    //         label={t('Vault-TVL')}
    //         isLoading={!fetchVaultsDataDone}
    //         xs={5}
    //         md={2}
    //       />
    //     </Hidden>
    //   </Grid>
    // </Grid>

    //     <Grid>
    //       <Grid
    //         item
    //         xs={12}
    //         style={{ border: "1px solid white", marginTop: "10px", padding: "10px", borderRadius: "10px" }}
    //       >
    //         <Grid
    //           item
    //           xs={12}
    //           style={{ display: 'flex', paddingTop: mobilePadding }}
    //           className={classes.mobilePadding}
    //         >
    //           <LabeledStat
    //             value={formatDecimals(balanceSingle)}
    //             subvalue={balanceUsd}
    //             label={t('Vault-Balance')}
    //             isLoading={!fetchBalancesDone}
    //             xs={6}
    //           />
    //           <LabeledStat
    //             value={formatApy(apy)}
    //             label={t('Vault-APY')}
    //             isLoading={!fetchApysDone}
    //             boosted={launchpool ? formatApy(launchpool.apy + apy) : ''}
    //             xs={4}
    //             align="start"
    //           />

    //         </Grid>
    //         <Grid item xs={12} style={{ display: 'flex', paddingTop: '20px' }}>
    //           <LabeledStat
    //             value={formatDecimals(deposited)}
    //             subvalue={depositedUsd}
    //             label={t('Vault-Deposited')}
    //             isLoading={!fetchBalancesDone}
    //             xs={6}
    //             align="start"
    //           />

    //           <LabeledStat
    //             value={calcDaily(apy)}
    //             label={t('Vault-APYDaily')}
    //             isLoading={!fetchApysDone}
    //             boosted={launchpool ? calcDaily(launchpool.apy + apy) : ''}
    //             xs={4}
    //           />

    //           <LabeledStat
    //             value={formatTvl(pool.tvl, pool.oraclePrice)}
    //             label={t('Vault-TVL')}
    //             isLoading={!fetchVaultsDataDone}
    //             xs={4} />

    //         </Grid>
    //       </Grid>
    //       <Grid xs={12} style={{ marginTop: "8px" }}>
    //         <Grid xs={8} style={{ display: "inline", textAlign: "center" }}>
    //           <button style={{ padding: "10px", fontSize: "16px", borderRadius: "10px", width: "230px", color: "White", fontWeight: "bold", background: "#b257c7" }}>
    //             10283103
    //           </button>
    //         </Grid>
    //         <Grid xs={3} style={{ display: "inline", textAlign: "center" }}>
    //           <button onClick={() => {console.log("hiiii");openCard()}} style={{ padding: "10px", fontSize: "16px", borderRadius: "10px", width: "50px", color: "White", fontWeight: "bold", background: "#b257c7", marginLeft: "24px" }}>
    //             <i class='fas fa-angle-down'></i>
    //           </button>
    //         </Grid>
    //       </Grid>

    //     </Grid>
    //   </Grid>


    // </AccordionSummary>
  );
};

const formatDecimals = number => {
  return number >= 10 ? number.toFixed(4) : number.isEqualTo(0) ? 0 : number.toFixed(8);
};

export default PoolSummary;
