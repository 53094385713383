import React, { useState, useCallback, memo, useEffect } from 'react';
import Accordion from '@material-ui/core/Accordion';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import BigNumber from 'bignumber.js';
import { Modal } from '@material-ui/core';
import { byDecimals } from 'features/helpers/bignumber';
import PoolSummary from '../PoolSummary/PoolSummary';
import PoolAccordion from '../PoolDetails/PoolAccordion';
import styles from './styles';
import { useSelector } from 'react-redux';


const useStyles = makeStyles(styles);

const Pool = ({
  pool,
  index,
  tokens,
  apy,
  fetchBalancesDone,
  fetchApysDone,
  fetchVaultsDataDone,
  // isOpen,
  // setIsOpen
}) => {
  const classes = useStyles();

  const [openIndex, setopenIndex] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const toggleCard = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const stake = useSelector(state => state.stake.pools);

  let balanceSingle = byDecimals(tokens[pool.token].tokenBalance, pool.tokenDecimals);
  let sharesBalance = new BigNumber(tokens[pool.earnedToken].tokenBalance);

  const checkLaunchpool = () => {
    const timestamp = Math.floor(Date.now() / 1000);
    for (let index in stake) {
      if (stake[index].token === pool.earnedToken && stake[index].periodFinish >= timestamp) {
        stake[index].poolIndex = Number(index) + 1;
        return stake[index];
      }
    }
  };

  const handleClose = () => {
    setIsOpen(!isOpen);
  }

  return (
    // <div style={{ maxWidth: "360px", width: "100%", minHeight: "340px", maxHeight: "340px", borderRadius: "15px", background: "#191D59" }}>
    // <Accordion
    //   expanded={isOpen}

    //   square={true}
    //   TransitionProps={{ unmountOnExit: true }}
    //   style={{ borderRadius: "20px", margin: "auto", margin: "10px" }}
    // >
    <div >
      <Grid item xs={12} sm={12} md={10} className={classes.container} style={{ width: "100%", borderRadius: "15px", background: "#191D59" }}>
        <PoolSummary
          pool={pool}
          launchpool={checkLaunchpool()}
          balanceSingle={balanceSingle}
          toggleCard={toggleCard}
          sharesBalance={sharesBalance}
          apy={apy}
          fetchBalancesDone={fetchBalancesDone}
          fetchApysDone={fetchApysDone}
          fetchVaultsDataDone={fetchVaultsDataDone}
          style={{ borderRadius: "20px" }}
          index={index}
          openCard={() => setIsOpen(!isOpen)}
        />
        {/* <div style={{marginTop:"20px"}}>ii</div> */}
        {/* {isOpen && <PoolAccordion
          pool={pool}
          balanceSingle={balanceSingle}
          sharesBalance={sharesBalance}
          index={index}
        />} */}
        {window.screen.width < 900 && isOpen &&
          <div style={{ position: "relative", background: "#8538BD", left: 0, display: "block", margin: "auto", top: "10%" }}>
            <PoolAccordion
              balanceSingle={balanceSingle}
              toggleCard={toggleCard}
              sharesBalance={sharesBalance}
              apy={apy}
              fetchBalancesDone={fetchBalancesDone}
              fetchApysDone={fetchApysDone}
              fetchVaultsDataDone={fetchVaultsDataDone}
              style={{ borderRadius: "20px" }}
              index={index}
              pool={pool}
              balanceSingle={balanceSingle}
              sharesBalance={sharesBalance}
              index={index}
            />
          </div>
        }
      </Grid>
      {window.screen.width > 900 && isOpen &&

        // <Modal>
        <div style={{position:"fixed", zIndex:"999", width:"100vw", height:"100vh", top:"0", left:"0", right:"0" , bottom:"0", background:"rgba(0,0,0,0.25)"}}>
          <div className={classes.container} style={{ position: "fixed", background: "#8538BD", zIndex: "9999", left: "8%", width: "100%", maxWidth: "83%", display: "block", margin: "auto", borderRadius: "20px", top: "8%" }}>
            <div onClick={() => setIsOpen(false)} style={{ padding: "20px", fontWeight: "bold", color: "white", cursor: "pointer", float: "right", clear: "right" }}>X</div>
            <br />
            <br />
            <PoolAccordion
              balanceSingle={balanceSingle}
              toggleCard={toggleCard}
              sharesBalance={sharesBalance}
              apy={apy}
              fetchBalancesDone={fetchBalancesDone}
              fetchApysDone={fetchApysDone}
              fetchVaultsDataDone={fetchVaultsDataDone}
              style={{ borderRadius: "20px" }}
              index={index}
              pool={pool}
              balanceSingle={balanceSingle}
              sharesBalance={sharesBalance}
              index={index}
            />
            <PoolSummary
              pool={pool}
              launchpool={checkLaunchpool()}
              balanceSingle={balanceSingle}
              toggleCard={toggleCard}
              sharesBalance={sharesBalance}
              apy={apy}
              fetchBalancesDone={fetchBalancesDone}
              fetchApysDone={fetchApysDone}
              fetchVaultsDataDone={fetchVaultsDataDone}
              style={{ borderRadius: "20px", marginTop: "-30px" }}
              index={index}
              page="detail"
              openCard={() => setIsOpen(!isOpen)}
            />
          </div>
        </div>
      }
    </div>

    // </Accordion>
    // </div>
    // <Accordion
    //   expanded={isOpen}
    //   className={classes.accordion}
    //   square={true}
    //   TransitionProps={{ unmountOnExit: true }}
    //   style={{ borderRadius: "20px", margin: "auto", maxWidth: "340px", margin: "10px" }}
    // >
    //   <PoolSummary
    //     pool={pool}
    //     launchpool={checkLaunchpool()}
    //     balanceSingle={balanceSingle}
    //     toggleCard={toggleCard}
    //     sharesBalance={sharesBalance}
    //     apy={apy}
    //     fetchBalancesDone={fetchBalancesDone}
    //     fetchApysDone={fetchApysDone}
    //     fetchVaultsDataDone={fetchVaultsDataDone}
    //     style={{ borderRadius: "20px" }}
    //     index={index}
    //     openCard={() => setIsOpen(!isOpen)}
    //   />

    // {/* <Divider variant="middle" className={classes.divider} /> */}
    // {/* {<PoolAccordion
    //   pool={pool}
    //   balanceSingle={balanceSingle}
    //   sharesBalance={sharesBalance}
    //   index={index}
    // />} */}
    // {/* <PoolWithdraw /> */}
    //   <Modal
    //     open={isOpen}
    //     onClose={handleClose}
    //     aria-labelledby="simple-modal-title"
    //     aria-describedby="simple-modal-description"
    //     className={classes.modal}
    //   >
    //     <div
    //       className={classes.modalBody}
    //     >
    //       <div style={{color:"white",float:"right", width:"100%", fontSize:"22px", cursor:"pointer"}} onClick={() => setIsOpen(false)}>X</div>
    //       <PoolAccordion
    //         balanceSingle={balanceSingle}
    //         toggleCard={toggleCard}
    //         sharesBalance={sharesBalance}
    //         apy={apy}
    //         fetchBalancesDone={fetchBalancesDone}
    //         fetchApysDone={fetchApysDone}
    //         fetchVaultsDataDone={fetchVaultsDataDone}
    //         style={{ borderRadius: "20px" }}
    //         index={index}
    //         pool={pool}
    //         balanceSingle={balanceSingle}
    //         sharesBalance={sharesBalance}
    //         index={index}
    //       />
    //     </div>
    //   </Modal>
    // </Accordion>

  );
};

export default memo(Pool);
