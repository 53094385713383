import { VaultPage } from './';
import { PoolPage } from './';


export default {
  path: 'vault',
  childRoutes: [
    { path: 'pool', component: PoolPage },
    { path: ':vaultId', component: VaultPage, isIndex: true }
  ]
};
